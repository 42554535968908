import classNames from "classnames";
import {
  ComponentType,
  forwardRef,
  HTMLInputTypeAttribute,
  InputHTMLAttributes,
  ReactNode,
} from "react";

import { ErrorIcon, IconProps } from "../icons";
import { DataAttributes } from "../types";
import { Typography } from "../typography";
import { convertDataAttributes } from "../utils";

export type SizeVariant = "EXTRA_SMALL" | "SMALL" | "REGULAR" | "LARGE";
type TextSizeVariant = "XS" | "SM" | "BASE" | "LG";
type ColorVariant = "DARK_BLUE" | "PRIMARY_BLUE_900" | "WHITE";
export type StateVariant = "DEFAULT" | "ERROR";
export type IconVariant = "SUCCESS" | "ALERT" | "GRAY";

const STATE_MAPS: Record<StateVariant, string> = {
  DEFAULT: "border border-blueGray200 focus:border-primaryBlue500",
  ERROR: "border bg-red10 border-red500 focus:border-red500 active:border-red500 ring-red10",
};

const SIZE_MAPS: Record<SizeVariant, string> = {
  EXTRA_SMALL: "h-6",
  SMALL: "",
  REGULAR: "h-10",
  LARGE: "h-11",
};

const COLOR_MAPS: Record<ColorVariant, string> = {
  DARK_BLUE: "text-primaryBlue900",
  PRIMARY_BLUE_900: "text-primaryBlue900",
  WHITE: "text-white",
};

const TEXT_SIZE_MAPS: Record<TextSizeVariant, string> = {
  XS: "text-xs",
  SM: "text-sm",
  BASE: "text-base",
  LG: "text-lg",
};

const LEFT_ICON_MAPS: Record<SizeVariant, string> = {
  EXTRA_SMALL: "",
  SMALL: "left-4",
  REGULAR: "left-4",
  LARGE: "left-4",
};

const RIGHT_ICON_MAPS: Record<SizeVariant, string> = {
  EXTRA_SMALL: "",
  SMALL: "right-4",
  REGULAR: "right-4",
  LARGE: "right-4",
};

const ICON_VARIANT_MAPS: Record<IconVariant, string> = {
  SUCCESS: "text-green500",
  ALERT: "text-red500",
  GRAY: "text-blueGray600",
};

export interface InputProps extends Omit<InputHTMLAttributes<HTMLInputElement>, "size"> {
  className?: string;
  iconVariant?: IconVariant;
  color?: ColorVariant;
  textSize?: TextSizeVariant;
  leftIcon?: ComponentType<IconProps> | undefined;
  rightIcon?: ComponentType<IconProps> | undefined;
  state?: StateVariant;
  size?: SizeVariant;
  helper?: string;
  helperRenderer?: (helper: string) => ReactNode;
  rounded?: boolean;
  dataAttributes?: DataAttributes;
  onRightIconClick?: () => void;
  leftIconSize?: number;
  rightIconSize?: number;
  disableErrorIcon?: boolean;
  errorMessage?: string;
  fluid?: boolean;
  renderErrorContainer?: boolean;
  type?: HTMLInputTypeAttribute | "percentage";
  placeholder: string; // Ensure placeholder is mandatory to meet a11y requirements
}

type RightIconProps = Pick<
  InputProps,
  | "state"
  | "disableErrorIcon"
  | "rightIcon"
  | "onRightIconClick"
  | "size"
  | "iconVariant"
  | "rightIconSize"
>;

function getRightComponentIcon({
  state,
  disableErrorIcon,
  rightIcon,
}: Pick<
  RightIconProps,
  "state" | "disableErrorIcon" | "rightIcon"
>): ComponentType<IconProps> | null {
  if (state === "ERROR" && !disableErrorIcon) return ErrorIcon;
  if (rightIcon) return rightIcon;

  return null;
}

const RightIcon = ({
  state,
  rightIcon,
  disableErrorIcon,
  onRightIconClick,
  size = "SMALL",
  iconVariant,
  rightIconSize,
}: RightIconProps) => {
  const Icon = getRightComponentIcon({ state, disableErrorIcon, rightIcon });

  if (!Icon) {
    return null;
  }

  const className = classNames(
    "absolute top-1/2 -translate-y-1/2",
    RIGHT_ICON_MAPS[size],
    iconVariant && ICON_VARIANT_MAPS[iconVariant]
  );

  if (onRightIconClick) {
    return (
      <button onClick={onRightIconClick} type="button" className={className}>
        <Icon size={rightIconSize} />
      </button>
    );
  }

  return <Icon className={className} size={rightIconSize} />;
};

export const Input = forwardRef<HTMLInputElement, InputProps>(
  (
    {
      rounded = true,
      size = "SMALL",
      state = "DEFAULT",
      dataAttributes = {},
      leftIcon: LeftIconComponent,
      rightIcon,
      iconVariant,
      className,
      onRightIconClick,
      leftIconSize,
      rightIconSize = 16,
      disableErrorIcon,
      errorMessage,
      fluid,
      renderErrorContainer,
      textSize = "SM",
      color = "DARK_BLUE",
      placeholder,
      type,
      value,
      ...props
    },
    ref
  ) => {
    const dataAttr = convertDataAttributes(dataAttributes);

    return (
      <span className="relative w-full">
        <div className="relative w-full">
          <div className={classNames("relative", fluid ? "w-full" : "w-min")}>
            <input
              className={classNames(
                "p-0 leading-8 focus:outline-none focus:ring-1 disabled:opacity-50 disabled:cursor-not-allowed",
                STATE_MAPS[state],
                SIZE_MAPS[size],
                COLOR_MAPS[color],
                TEXT_SIZE_MAPS[textSize],
                {
                  rounded,
                  "pl-10": LeftIconComponent,
                  "pl-4": !LeftIconComponent,
                  "w-full": fluid,
                },
                className
              )}
              {...dataAttr}
              {...props}
              value={value}
              type={type === "percentage" ? "number" : type}
              placeholder={placeholder}
              ref={ref}
            />
            {LeftIconComponent && (
              <LeftIconComponent
                className={classNames(
                  "absolute top-1/2 -translate-y-1/2",
                  LEFT_ICON_MAPS[size],
                  iconVariant && ICON_VARIANT_MAPS[iconVariant]
                )}
                size={leftIconSize || 16}
              />
            )}
            <RightIcon
              size={size}
              state={state}
              rightIcon={rightIcon}
              rightIconSize={rightIconSize}
              onRightIconClick={onRightIconClick}
              disableErrorIcon={disableErrorIcon}
              iconVariant={iconVariant}
            />
            {value && type === "percentage" && (
              <div
                className={classNames(
                  "absolute top-1/2 left-4 -translate-y-[9.5px] z-0 pointer-events-none",
                  COLOR_MAPS[color],
                  TEXT_SIZE_MAPS[textSize]
                )}
              >
                <span className="invisible mr-1">{value}</span>%
              </div>
            )}
          </div>
        </div>
        <div
          className={classNames("mt-1 h-5", {
            hidden: !renderErrorContainer && !errorMessage,
          })}
        >
          {errorMessage && (
            <Typography component="h6" color="text-red700">
              {errorMessage}
            </Typography>
          )}
        </div>
      </span>
    );
  }
);
